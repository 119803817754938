<template>
    <div class="common-layout">
        <router-view></router-view>
        <!-- <el-container>
            <el-container>
                <el-menu background-color="none" unique-opened text-color="#fff" :router="true"
                    class="el-menu-vertical-demo" :default-active="$route.path">
                    <el-menu-item index="/homePage">产品</el-menu-item>
                    <el-menu-item index="/support">服务支持</el-menu-item>
                    <el-menu-item index="/downLoad">下载</el-menu-item>
                </el-menu>
            </el-container>
            <el-main>

                <homePage :index="index" v-if="currIndex == 0"></homePage>
                <support v-if="currIndex == 1"></support>
                <downLoad v-if="currIndex == 2"></downLoad>
                <router-view></router-view>
            </el-main>
        </el-container> -->
    </div>
    <el-dialog v-model="dialogVisible" top="30vh" width="80%" :close-on-click-modal="false" :show-close="false">
        <el-text tag="div" style="font-size: 20px;">检测到您是手机访问，点击确定跳转手机端官网</el-text>
        <template #footer>
            <el-button type="primary" style="width: 80px;font-size: 10px; height: 30px;" size="large" @click="handleSure">
                确定
            </el-button>
        </template>
    </el-dialog>
</template>

<script>
import { ElMessage, ElMessageBox } from "element-plus";

export default {

    data() {
        return {
            dialogVisible: false
        }
    },

    mounted() {
        let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
        if (flag) {
            this.dialogVisible = true
            // ElMessageBox({
            //     title: '提示',
            //     message: '检测到您是手机访问，是否跳转手机端官网',
            //     showCancelButton: true,
            //     confirmButtonText: '确定',
            //     cancelButtonText: '取消',
            // }).then(() => {
            //     window.open('http://apiapp.hrzp.top/m_site/')
            // }).catch(() => {
            // });


            // window.open('http://apiapp.hrzp.top/m_site/')
            // ElMessageBox({
            //     title: '提示',
            //     message: '检测到您是手机访问，是否跳转手机端官网',
            // }).catch(() => { window.open('http://apiapp.hrzp.top/m_site/') });

        }
        console.log(flag);

    },

    methods: {
        handleSure() {
            window.open('http://apiapp.hrzp.top/m_site/')
        },
        // 顶部点击事件
        handleTop(value) {
            if (value == 1) {
                this.currIndex = 1
                console.log('服务支持');
                this.$router.push({
                    path: '/support',
                    query: { id: '0' }
                })
            }

            if (value == 2) {
                this.currIndex = 2
                // console.log('合作渠道');
                // this.$router.push({
                //     path: '/downLoad',
                //     query: { id: '0' }
                // })
            }
        },

        // 产品点击事件
        handlePro(index) {
            if (this.currIndex != 0) {
                this.currIndex = 0
                this.index = index
            } else {
                this.index = index
            }


            // this.$router.push({
            //     path: '/homePage',
            //     query: { index: index }
            // })


            // let path = ref("")
            // const route = useRoute()

            // path.value = route.path

            // if (index < 7) {
            //     window.scrollTo({
            //         top: this.$route.path.$refs['introduceR'].getBoundingClientRect().top + window.scrollY - 70,
            //         behavior: 'smooth' // 平滑滚动
            //     })
            //     this.handleintroduceMouseover(index)
            // }

            // if (index == 7) {
            //     window.scrollTo({
            //         top: this.$refs['manageR'].getBoundingClientRect().top + window.scrollY - 70,
            //         behavior: 'smooth' // 平滑滚动
            //     })
            // }



            console.log(index);

        },
        // 鼠标悬停事件
        handleMouseover(value) {
            let element = document.getElementsByClassName('product-class')
            element[value].style.color = '#225DFF'
            if (value == 0) {
                this.isNorOne = true
            }
            if (value == 3) {
                this.isNorTwo = true
            }
            if (value == 4) {
                this.isNorThr = true
            }
        },
        // 鼠标离开事件
        handleMouseout(value) {
            let element = document.getElementsByClassName('product-class')
            element[value].style.color = '#141111'

            if (value == 0) {
                this.isNorOne = false
            }
            if (value == 3) {
                this.isNorTwo = false
            }
            if (value == 4) {
                this.isNorThr = false
            }
        },
        hadnleDefault() {
            let element = document.getElementsByClassName('mangage-btn')[0]
            element.style.background = '#2362FB'
            element.style.color = '#fff'

            let introduceDiv = document.getElementsByClassName('introduce-div')[0]
            introduceDiv.style.background = '#F5FAFF'

            let introduceTit = document.getElementsByClassName('introduce-tit')[0]
            introduceTit.style.color = '#225DFF'
        },
    },


}
</script>

<style >
.customPopper {
    width: 1203px !important;
}
</style>
<style scoped>
.common-layout {
    width: 100%;
}

.flex-layout {
    display: flex;
}

.top-class {
    /* position: absolute; */

    /* top: 0;
    left: 0; */
    display: flex;
    width: 100%;
    height: 82px;
    /* background: cyan; */
}

.top-btn {
    width: 100px;
    height: 42px;
    margin: 20px 0 0 350px;
}

.top-finBtn {
    width: 100px;
    height: 42px;
    margin: 20px 0 0 20px;
}

.logo-class {
    /* vertical-align: middle; */
    padding: 20px 0 0 366px;
    width: 42px;
    height: 42px;

}

.product-class {
    color: #141111;
    margin: 0 0 0 50px;
}

.hover-one {
    display: flex;
    width: 1153px;
    height: 453px;
}

.flex-wrap {
    display: flex;
    flex-wrap: wrap;
}

.one-left {
    width: 848px;
    height: 412px;
    /* background: blue; */
}

.one-right {
    width: 307px;
    height: 413px;
    /* background: cyan; */
}

.one-div {
    width: 230px;
    height: 63px;
    margin: 40px 0 0 40px;
    /* background: gray; */
}

.img-one {
    padding: 30px 0 0 16px;
    width: 12px;
    height: 12px;
}

.img-two {
    padding: 22px 0 0 16px;
    width: 12px;
    height: 12px;
}

.text-note {
    display: inline-block;
    padding: 0 0 0 10px;
    color: #707070;
    font-size: 12px;
}

.text-one {
    padding: 40px 0 0 16px;
    color: #222665;
    font-size: 18px;
}

.text-two {
    padding: 3px 0 0 16px;
    color: #222665;
    font-size: 32px;
    line-height: 46px;
}

.pro-icon {
    padding: 21px 0 0 32px;
    width: 20px;
    height: 20px;
}

.text-tit {
    margin: 12px 0 0 13px;
    color: #141111;
    font-size: 14px;
    font-weight: 700;
}

.text-det {
    margin: 3px 0 0 13px;
    color: #9BA0C7;
    font-size: 10px;
}

.hover-two {
    width: 1153px;
    height: 143px;
    background: #fff;
}

.small-icon {
    margin: 32px 0 0 5px;
    width: 18px;
    height: 18px;
}

.merit-out {
    padding: 50px 0 0 355px;
}

.merit-class {
    margin: 0 0 0 74px;
    width: 211px;
    height: 50px;
    /* background: cyan; */
}

.merit-icon {
    margin: 0 0 0 12px;
    width: 50px;
    height: 50px;
}

.merit-tit {
    padding: 2px 0 0 20px;
    color: #00152A;
    font-size: 16px;
    font-weight: 700;
    line-height: 23px;
}

.merit-det {
    padding: 3px 0 0 20px;
    color: #707070;
    font-size: 14px;
    line-height: 20px;
}

.merit-num {
    padding: 0 0 0 5px;
    color: #ED1C24;
    font-weight: 700;
    font-size: 24px;
    line-height: 23px;
}

.help-tit {
    margin: 102px 0 0 0;
    color: #222665;
    font-size: 36px;
    width: 100%;
    text-align: center;
}

.help-det {
    margin: 16px 0 0 0;
    color: #A0A3C7;
    font-size: 20px;
    width: 100%;
    text-align: center;
}
</style>