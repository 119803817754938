import { createRouter, createWebHashHistory } from 'vue-router'
import layout from '../views/layout.vue'

const routes = [
  {
    path: '/',
    name: 'layout',
    component: layout,
    redirect: '/homePage',
    children: [
      {
        path: '/homePage',
        name: 'homePage',
        component: () => import('../views/homePage.vue'),
      },
      {
        path: '/support',
        name: 'support',
        component: () => import('../views/support.vue'),
      },
      {
        path: '/downLoad',
        name: 'downLoad',
        component: () => import('../views/downLoad.vue'),
      },
      {
        path: '/applyTry',
        name: 'applyTry',
        component: () => import('../views/applyTry.vue'),
      },
      {
        path: '/channel',
        name: 'channel',
        component: () => import('../views/channel.vue'),
      },
      {
        path: '/aboutus',
        name: 'aboutus',
        component: () => import('../views/aboutus.vue'),
      },

    ]
  },

]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
