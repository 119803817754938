<template>
  <router-view />
</template>

<script>


// export default {
//   created() {
//     this.initWebSocket();
//   },
//   methods: {
//     //app.vue
//     initWebSocket() {
//       let that = this;
//       if ("WebSocket" in window) {
//         console.log("您的浏览器支持 WebSocket!");
//         that.ws = new WebSocket('ws://apiapp.hrzp.top:8081');
//         that.$global.setWs(that.ws);
//         // that.ws.onopen = that.onopen();
//         that.ws.onopen = function () { console.log('webSocket connect successful') };
//         that.ws.onclose = function () {
//           // 关闭 websocket
//           console.log("webSocket connect closed");
//           setTimeout(() => {
//             that.initWebSocket();
//           }, 2000);
//         };
//       } else {
//         // 浏览器不支持 WebSocket
//         console.log("您的浏览器不支持 WebSocket!");
//       }
//     },
//   }
// }


const debounce = (fn, delay) => {
  let timer = null;
  return function () {
    let context = this;
    let args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  }
}

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  constructor(callback) {
    callback = debounce(callback, 16);
    super(callback);
  }
}



</script>
<style>
body,
html {
  padding: 0;
  margin: 0;
  background: #fff;
  /* background: rgb(240, 242, 245); */
}
</style>
