import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import 'lib-flexible/flexible'
import "amfe-flexible/index"
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'


import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import locale from 'element-plus/dist/locale/zh-cn.mjs'
const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
// 全局设置路由跳转页面时，页面是在顶部
router.afterEach((to, from, next) => {
    window.scrollTo(0, 0);
})
app.use(ElementPlus, { locale }).use(store).use(router).mount('#app')
